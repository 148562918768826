import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionHeading from "../components/sectionheading"
import Posts from "../components/posts"
import {NextPage} from "../pages/index"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const menuLinks = data.site.siteMetadata.menuLinks
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        menuLinks={menuLinks}
      >
        <SEO title="All blogs" />
        <SectionHeading
          title={"Blogs"}
          description={"Full sized blogs. That are very much necessary."}
          linkName={"Back"}
          pageLink=""
        />
        <Posts posts={posts} post_type="blog" />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        menuLinks {
          link
          name
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            post_type
          }
        }
      }
    }
  }
`
